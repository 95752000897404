<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="订单号">
					<c-input name="order_number"></c-input>
				</m-search-item>
				<m-search-item label="客户名称">
					<c-input name="customer_name"></c-input>
				</m-search-item>
				<m-search-item label="产品">
					<c-input name="product_name"></c-input>
				</m-search-item>
				<m-search-item label="订单状态">
					<c-select name="state" :options="orderState"></c-select>
				</m-search-item>
				<m-search-item label="派工状态">
					<c-select name="task_state" :options="taskState"></c-select>
				</m-search-item>
				<m-search-item label="报工状态">
					<c-select name="work_state" :options="workState"></c-select>
				</m-search-item>
			</m-search>
			
			<c-table ref="table" height="grow">
				<c-table-column
					label="订单号"
					name="order_number"
					width="100"
				>
				</c-table-column>
				<c-table-column
					label="客户名称"
					name="customer_name"
					width="120"
				>
				</c-table-column>
				<c-table-column
					label="产品"
					name="product_name"
					width="180"
				>
					<template #default="{data}">
						<span>{{data.product_name}}</span>
						<span v-if="data.product_code">【{{data.product_code}}】</span>
					</template>
				</c-table-column>
				<c-table-column
					label="订单状态"
					width="80"
					name="state"
				>
					<template #default="{data}">
						<span v-if="data.state == -1" style="color:red">待派工</span>
						<span v-if="data.state == 1" style="color:blue">待发货</span>
						<span v-if="data.state == 2" style="color:brown">部分发货</span>
						<span v-if="data.state == 3" style="color:green">发货完成</span>
						<span v-if="data.state == 4" style="color:yellow">已特结</span>
					</template>
				</c-table-column>
				<c-table-column
					label="派工状态"
					width="80"
					name="task_state"
				>
					<template #default="{data}">
						<span v-if="data.task_state == 1" style="color:blue">待派工</span>
						<span v-if="data.task_state == 2" style="color:brown">部分派工</span>
						<span v-if="data.task_state == 3" style="color:green">派工完成</span>
					</template>
				</c-table-column>
				<c-table-column
					label="报工状态"
					width="80"
					name="work_state"
				>
					<template #default="{data}">
						<span v-if="(data.task_state == 1)" style="color:#999">-</span>
						<span v-if="(data.task_state == 2 || data.task_state == 3) && data.work_state == 1" style="color:blue">待报工</span>
						<span v-if="(data.task_state == 2 || data.task_state == 3) && data.work_state == 2" style="color:brown">部分报工</span>
						<span v-if="(data.task_state == 2 || data.task_state == 3) && data.work_state == 3" style="color:green">报工完成</span>
					</template>
				</c-table-column>
				<c-table-column
					label="订单商品数"
					name="product_count"
					width="80"
				></c-table-column>
				<c-table-column
					label="已派工数"
					name="task_count"
					width="70"
				></c-table-column>
				<c-table-column
					label="已报工数"
					name="work_count"
					width="70"
				></c-table-column>
				<c-table-column
					label="合格数"
					name="qualified_count"
					width="70"
				>
					<template #default="{data}">
						<span v-if="data.last_approval_date">{{data.qualified_count}}</span>
					</template>
				</c-table-column>
				<c-table-column
					label="不合格数"
					name="unqualified_count"
					width="70"
				>
					<template #default="{data}">
						<span v-if="data.last_approval_date">{{data.unqualified_count}}</span>
					</template>
				</c-table-column>
				<c-table-column
					label="已发货数"
					name="send_count"
					width="70"
				></c-table-column>
				<c-table-column
					label="下单时间"
					name="create_date"
					width="115"
				>
				</c-table-column>
				<c-table-column
					label="最近派工时间"
					name="last_task_date"
					width="115"
				>
				</c-table-column>
				<c-table-column
					label="最近报工时间"
					name="last_work_date"
					width="115"
				>
				</c-table-column>
				<c-table-column
					label="最近质检审批"
					name="last_approval_date"
					width="115"
				>
				</c-table-column>
				<c-table-column
					label="最近发货时间"
					name="last_delivery_date"
					width="115"
				>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
    import {mapState} from 'vuex'
	export default {
		name: 'm_data_order_production',
		
		components: {
			
		},
		
		data() {
			return {
				orderState: [{
					name: '草稿',
					value: "-2"
				},{
					name: '待发货',
					value: "1"
				},{
					name: '部分发货',
					value: "2"
				},{
					name: '发货完成',
					value: "3"
				},{
					name: '已特结发货',
					value: "4"
				}],
				taskState: [{
					name: '待派工',
					value: "1"
				},{
					name: '部分派工',
					value: "2"
				},{
					name: '派工完成',
					value: "3"
				}],
				workState: [{
					name: '待报工',
					value: "1"
				},{
					name: '部分报工',
					value: "2"
				},{
					name: '报工完成',
					value: "3"
				}]
			}
		},
		
		computed: {
			
		},
		
		mounted() {
			setTimeout(() => {
				this.$refs.search.search();
			});
		},
		
		methods: {
			//搜索
			searchHandle(data) {
				this.$refs.table.load({
					url: '/data/order/production/list',
					data
				});
			}
		}
	}
</script>